import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/angular-ivy';

import { environment } from './environments/environment';


fetch('assets/appsettings.json')
    .then((response) => response.json())
    .then((json) => {
        window.AppConfig = json.AppWebSettings;
        import('./app/app.module').then(module => {
            platformBrowserDynamic().bootstrapModule(module.AppModule)
                // eslint-disable-next-line no-console
                .catch(err => console.log(err));
        });

        Sentry.init({
            enabled: environment.production,
            dsn: AppConfig.Sentry.DsnUi,
            integrations: [
                new BrowserTracing({
                    tracePropagationTargets: [AppConfig.ClientUrl],
                    routingInstrumentation: Sentry.routingInstrumentation
                })
            ],
            tracesSampleRate: 0.0,
            ignoreErrors: [
                'Http failure response for',
                'Non-Error exception captured'
            ]
        });
    });


if (environment.production) {
    enableProdMode();
}

